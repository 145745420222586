import {
  fromGlobalId,
  ICompanyCreateAndJoinMutation,
  toGlobalId,
  useCompanyCreateAndJoinMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useMutation as useTrpcMutation, useQuery } from '@invisible/trpc/client'
import { useToasts } from '@invisible/ui/toasts'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { get } from 'lodash/fp'
import { useRouter } from 'next/router'
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { NEXT_PUBLIC_ADMIN_URL } from '../../../config/env'
import { generateEmail } from '../helpers'

interface OnboardCompanyModalProps {
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

interface UltronCompany {
  id: string
  name: string
  // Add other properties as needed
}

interface SalesforceOnboardingData {
  name: string
  salesforceId: string
  ownerName: string
  ownerEmail: string
  website?: string
  industry?: string
  operationsDirectorId?: string
  operationsManagerId?: string
}

const SALESFORCE = 'salesforce'
const MANUALLY = 'manually'

export default function OnboardCompanyModal({ modalOpen, setModalOpen }: OnboardCompanyModalProps) {
  const [creatingCompany, setCreatingCompany] = useState(false)
  const [salesforceAccountId, setSalesforceAccountId] = useState('')
  const [assistantName, setAssistantName] = useState('')
  const [onboardType, setOnboardType] = useState<string>(SALESFORCE)
  const [companyName, setCompanyName] = useState('')
  const { mutateAsync: updateCompany } = useTrpcMutation('company.updateStripeAndXeroIds', {})
  const { mutateAsync: companyCreateAndJoin } = useCompanyCreateAndJoinMutation()

  const { addToast } = useToasts()
  const router = useRouter()

  const { refetch: fetchSalesforceData } = useQuery(
    [
      'company.getSalesforceData',
      {
        tableName: 'Account',
        fieldNames: ['Name', 'Website', 'Industry'],
        whereClause: `Id = '${salesforceAccountId}'`,
      },
    ],
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  const { refetch: fetchCompanyEmailFromSalesforce } = useQuery(
    [
      'company.getSalesforceData',
      {
        tableName: 'Contact',
        fieldNames: ['Name', 'Email'],
        whereClause: `AccountId = '${salesforceAccountId}'`,
      },
    ],
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  const { refetch: fetchOpportunitiesFromSalesforce } = useQuery(
    [
      'company.getSalesforceData',
      {
        tableName: 'Opportunity',
        fieldNames: ['Operations_Director_Ultron_ID__c', 'Operations_Manager_Ultron_ID__c'],
        whereClause: `AccountId = '${salesforceAccountId}'`,
      },
    ],
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  )

  const createStripeAndXeroIds = async ({
    name,
    email,
    manticoreCompanyId,
  }: {
    name: string
    email: string
    manticoreCompanyId: string
  }) => {
    try {
      const args = { email, name }

      const stripeResponse = await axios
        .post(`/api/stripe/customer`, args)
        .then(({ data }) => ({ success: true, data, error: null }))
        .catch((error) => {
          logger.error('Failed to create a Stripe entry', { error })
          return { success: false, data: null, error }
        })

      const stripeCustomerId =
        !!stripeResponse?.success && !!stripeResponse?.data
          ? stripeResponse?.data?.stripeCustomerId
          : null
      if (!stripeCustomerId) {
        logger.error(`Failed to create a Stripe entry for companyName: ${name}`, {
          companyName: name,
          manticoreCompanyId,
        })
      }

      if (!stripeCustomerId) return

      const updatedDetails = await updateCompany({
        id: manticoreCompanyId,
        stripeCustomerId,
      })

      return updatedDetails
    } catch (error: unknown) {
      logger.error('Failed to update a Stripe entry of company', {
        error,
        companyName: name,
        companyId: manticoreCompanyId,
      })
      return 'stripe_xero_creation_failed'
    }
  }

  const validateAssistant = async (assistantEmail: string) => {
    try {
      const res = await axios
        .post(`${NEXT_PUBLIC_ADMIN_URL}/api/internal-onboarding/validateAssistant`, {
          assistantEmail,
        })
        .then(get('data'))
        .catch((error) => {
          logger.error('Failed to validate Assistant', { error })
        })

      return res?.isValid
    } catch (error: unknown) {
      logger.error(`Error validating assistant name: ${assistantEmail}`, { error })
      return 'assistant_validation_failed'
    }
  }

  const addCompany = async () => {
    let ultronCompany: ICompanyCreateAndJoinMutation
    let confirmedUltronCompany: UltronCompany | undefined

    if (onboardType === MANUALLY) {
      if (!companyName) {
        addToast('Please enter company name', { appearance: 'error' })
        return
      }
      const dataToSave = {
        name: companyName,
        assistantEmail: `${companyName.replace(/\s+/g, '')}@inv.tech`,
      }
      setCreatingCompany(true)
      try {
        ultronCompany = await companyCreateAndJoin({
          data: dataToSave,
        })
        if (ultronCompany?.companyCreateAndJoin?.__typename !== 'CompanyType') {
          addToast('Failed to create company', { appearance: 'error' })
          return
        }
        confirmedUltronCompany = ultronCompany.companyCreateAndJoin
        setCreatingCompany(false)
        logger.info(`Successfully created new company ${dataToSave.name}`)
        addToast(`Successfully created new company ${dataToSave.name}`, { appearance: 'success' })
        const id = fromGlobalId(confirmedUltronCompany.id)
        router.push(`/companies/${id}`)

        return
      } catch (error: unknown) {
        logger.error('Error when onboarding company', {
          error,
          ultronCompany: JSON.stringify(confirmedUltronCompany),
          dataToSave: JSON.stringify(dataToSave),
        })
      } finally {
        setCreatingCompany(false)
      }
    }
    if (salesforceAccountId.length === 0) {
      addToast('Please enter salesforce account id', { appearance: 'error' })
      return
    }

    if (assistantName.length === 0) {
      addToast('Please enter assistant Name', { appearance: 'error' })
      return
    }

    setCreatingCompany(true)
    const assistantEmail = generateEmail(assistantName)
    const salesforceOnboardingData = await fetchOnboardingSalesforceData()

    if (!salesforceOnboardingData) {
      logger.error('Could not fetch Salesforce data', { salesforceAccountId })
      return
    }

    const dataToSave: SalesforceOnboardingData & { assistantEmail: string } = {
      ...salesforceOnboardingData,
      assistantEmail,
    }

    try {
      const assistantValidationResult = await validateAssistant(assistantEmail)
      if (
        !assistantValidationResult ||
        assistantValidationResult === 'assistant_validation_failed'
      ) {
        addToast(`There was an error validating the assistant name: ${assistantName}`, {
          appearance: 'error',
        })
        return
      }

      if (dataToSave.operationsDirectorId) {
        dataToSave.operationsDirectorId = toGlobalId('CompanyType', dataToSave.operationsDirectorId)
      }
      if (dataToSave.operationsManagerId) {
        dataToSave.operationsManagerId = toGlobalId('CompanyType', dataToSave.operationsManagerId)
      }
      ultronCompany = await companyCreateAndJoin({
        data: dataToSave,
      })
      if (ultronCompany?.companyCreateAndJoin?.__typename !== 'CompanyType') {
        addToast('Failed to create company', { appearance: 'error' })
        return
      }

      confirmedUltronCompany = ultronCompany.companyCreateAndJoin
      const id = fromGlobalId(confirmedUltronCompany.id)

      const stripeXeroResult = await createStripeAndXeroIds({
        name: dataToSave.name,
        email: dataToSave.ownerEmail,
        manticoreCompanyId: id,
      })
      if (stripeXeroResult === 'stripe_xero_creation_failed') {
        addToast(
          `Company ${dataToSave.name} created,\nbut with 3rd-party service errors.\nClick here to view Company Profile.`,
          {
            appearance: 'info',
            autoDismiss: false,
            onDismiss: () => router.push(`/companies/${id}`),
          }
        )
        return
      }

      const netsuiteResult = await createNetsuiteCustomer({
        companyName: confirmedUltronCompany.name,
        email: dataToSave.ownerEmail,
        externalId: id,
      })
      if (netsuiteResult === 'netsuite_creation_failed') {
        addToast(
          `Company ${dataToSave.name} created,\nbut Netsuite creation failed.\nClick here to view Company Profile.`,
          {
            appearance: 'info',
            autoDismiss: false,
            onDismiss: () => router.push(`/companies/${id}`),
          }
        )
        return
      }

      logger.info(`Successfully created new company ${dataToSave.name}`)
      addToast(`Successfully created new company ${dataToSave.name}`, { appearance: 'success' })
      router.push(`/companies/${id}`)
    } catch (error: unknown) {
      logger.error('Error when onboarding company', {
        error,
        ultronCompany: JSON.stringify(confirmedUltronCompany),
        dataToSave: JSON.stringify(dataToSave),
      })
    } finally {
      setCreatingCompany(false)
    }
  }
  const handleSalesforceError = (message = 'Could not fetch Salesforce data', error?: unknown) => {
    setCreatingCompany(false)
    addToast(message, { appearance: 'error' })
    logger.error(message, {
      salesforceAccountId,
      error,
    })
  }

  const createNetsuiteCustomer = async ({
    companyName,
    email,
    externalId,
  }: {
    companyName: string
    email: string
    externalId: string
  }) => {
    try {
      const args = { companyName, email, externalId }
      const response = await axios
        .post(`/api/netsuite/upsert-customer`, args)
        .then(({ data }) => data)
        .catch((error) => {
          logger.error('Failed to create a Netsuite entry', { error })
        })

      const netsuiteCustomerEID = response?.externalId
      if (!netsuiteCustomerEID) {
        logger.error(`Failed to create customer in Netsuite for company: ${companyName}`, {
          companyName,
          companyId: externalId,
        })
      }
      return netsuiteCustomerEID
    } catch (error: unknown) {
      logger.error(`Failed to create customer in Netsuite for company: ${companyName}`, {
        error,
        companyName,
        companyId: externalId,
      })
      return 'netsuite_customer_creation_failed'
    }
  }

  const fetchOnboardingSalesforceData = async () => {
    try {
      const salesforceData = await fetchSalesforceData()
      const salesforceContactEmail = await fetchCompanyEmailFromSalesforce()
      const salesforceOpportunitiesData = await fetchOpportunitiesFromSalesforce()
      if (
        !salesforceData?.data ||
        !salesforceContactEmail?.data ||
        !salesforceOpportunitiesData?.data
      ) {
        handleSalesforceError()
        return
      }
      if (!salesforceData.data.Name) {
        handleSalesforceError('Could not fetch company name from Salesforce')
        return
      }
      if (!salesforceContactEmail.data.Name || !salesforceContactEmail.data.Email) {
        handleSalesforceError('Could not fetch contact name and email from Salesforce')
        return
      }

      const { data } = salesforceData
      const { data: contactEmailData } = salesforceContactEmail
      const { Operations_Manager_Ultron_ID__c, Operations_Director_Ultron_ID__c } =
        salesforceOpportunitiesData.data
      return {
        name: data.Name,
        salesforceId: salesforceAccountId,
        ownerName: contactEmailData.Name,
        ownerEmail: contactEmailData.Email,
        website: data.Website,
        industry: data.Industry,
        operationsDirectorId:
          Operations_Director_Ultron_ID__c !== 'undecided'
            ? Operations_Director_Ultron_ID__c
            : undefined,
        operationsManagerId:
          Operations_Manager_Ultron_ID__c !== 'undecided'
            ? Operations_Manager_Ultron_ID__c
            : undefined,
      }
    } catch (error: unknown) {
      handleSalesforceError('Failed to fetch Salesforce data', { error })
    }
  }
  return (
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '0.375rem',
        },
      }}>
      <DialogTitle>Add Company</DialogTitle>
      <DialogContent>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='onboard-type'
            name='onboard-type'
            value={onboardType}
            onChange={(e) => setOnboardType(e.target.value)}>
            <FormControlLabel
              value={SALESFORCE}
              control={<Radio />}
              label='Onboard with Salesforce.'
            />
            <FormControlLabel value={MANUALLY} control={<Radio />} label='Onboard Manually.' />
          </RadioGroup>
        </FormControl>
        {onboardType === SALESFORCE ? (
          <>
            <TextField
              label='Salesforce Account Id'
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSalesforceAccountId(e.target.value)
              }
              required
              margin='dense'
            />
            <TextField
              label='Assistant Name'
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) => setAssistantName(e.target.value)}
              required
              margin='dense'
            />
          </>
        ) : (
          <TextField
            label='Company Name'
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value)}
            required
            margin='dense'
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color='primary'>
          Cancel
        </Button>
        <LoadingButton
          onClick={addCompany}
          color='primary'
          variant='contained'
          loading={creatingCompany}>
          Import
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
